import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import * as CONST from "./const";
import arrowLong from "../images/arrow_long.svg";
import arrowShort from "../images/arrow_short.svg";

function MoreLink({ link_to, add_class = "", is_arrow_long = "true", text = "more", }) {
	const external = /^http/.test(link_to);
	let link_class = CONST.UP_AND_DOWN_CENTER + " justify-end float-right block text-right text-white bg-black text-base md:text-sm tracking-wider py-6 " + add_class;
	let img_class = "w-2/12 float-right h-4 ";
	let arrow;
	if (is_arrow_long == "true") {
		arrow = arrowLong;
		link_class += " px-4 xl:pr-8 xl:py-8 my-5 xl:my-10 w-10/12 xs:w-6/12 xl:float-left";
		img_class += " ml-5"
	} else {
		arrow = arrowShort;
		link_class += " px-2 md:py-4 float-right w-9/12 xs:w-5/12 md:w-6/12";
		img_class += " ml-2"
	}

	if (external) {
		return (
			<a href={link_to} className={link_class} target="_blank" rel="noreferrer">{text}
				<img alt="" className={img_class} src={arrow} />
			</a>
		);
	} else {
		return (
			<Link to={link_to} className={link_class}>{text}
				<img alt="" className={img_class} src={arrow} />
			</Link>
		);
	}
}

MoreLink.propTypes = {
	link_to: PropTypes.string.isRequired,
	add_class: PropTypes.string,
	text: PropTypes.string,
	is_arrow_long: PropTypes.string,
};

export default MoreLink;
