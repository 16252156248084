import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import * as CONST from "../../components/const";
import MoreLink from "../../components/morelink";
const kv_h = "../../images/business/kv_h.png";
const kv_v = "../../images/business/kv_v.png";
// const bg = "../../images/business/business_org.png";
const bg_h = "../../images/business/bg_h.png";
const bg_v = "../../images/business/bg_v.png";
const img1 = "../../images/business/img1.png";
const img2 = "../../images/business/img2.png";
const img3 = "../../images/business/img3.png";

function IndexPage() {
  const STATIC_IMAGE_PLACEHOLDER = "blurred"; // blurred tracedSVG none

  return (
    <Layout>
      <SEO
        description="ALPHA WAVEの事業内容です。開発事業、環境構築事業、情報セキュリティーサービス事業、教育事業をご覧ください。"
        title="Business"
      />

      <section className={CONST.SECTION_AREA_CLASS + " xl:pb-28"}>
        <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_SHOW_CLASS + CONST.BUSINESS_BACK_GROUND_PATTERN_CLASS} src={bg_h} />
        <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_HIDDEN_CLASS + CONST.BUSINESS_BACK_GROUND_PATTERN_CLASS} src={bg_v} />
        <div className={CONST.KEY_VISUAL_OFFSET_TITLE_AREA_CLASS + " xl:text-white"}>
          <p className={CONST.KEY_VISUAL_TITLE_EN_CLASS}>Business content <span className={CONST.KEY_VISUAL_TITLE_JP_CLASS}>事業内容</span></p>
        </div>

        <div className={CONST.KEY_VISUAL_LEFT_IMG_AREA_CLASS}>
          <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_SHOW_CLASS + CONST.KEY_VISUAL_VARIABLE_IMG_CLASS} src={kv_h} />
          <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_HIDDEN_CLASS + CONST.KEY_VISUAL_VARIABLE_IMG_CLASS} src={kv_v} />
        </div>
        <div className={CONST.KEY_VISUAL_OFFSET_MESSAGE_AREA_RIGHT_OFFSET_CLASS}></div>
        <div data-aos="fade-left" className={CONST.KEY_VISUAL_OFFSET_MESSAGE_AREA_RIGHT_CLASS + " xl:pt-10 2xl:pt-10 3xl:pt-10 tracking-wider sm:leading-11 "}>
          <div >
            <h1 className={CONST.H1_CLASS + " font-light sm:leading-tight"}>
              <span className="">あらゆる分野、規模の</span>
              <span className="">ソフトウェア開発と</span>
              <span className="">サービスの提供</span>
            </h1>
            <p>開発事業</p>
            <p>環境構築事業</p>
            <p>情報セキュリティーサービス事業</p>
            <p>教育事業</p>
          </div>
        </div>
        {/* <div className={CONST.KV_BACK_GROUND_GLAY_AREA_CLASS}></div> */}
      </section>

      <section className={CONST.SECTION_P_AREA_CLASS + " mt-10 xl:-mt-20 3xl:-mt-28"}>
        <h2 data-aos="fade-up" className={CONST.H2_CLASS + " z-20"}>開発事業</h2>
        <div className="mt-5 sm:mt-6 md:mt-8 xl:mt-20 grid grid-cols-1 xl:grid-cols-3 gap-10 xl:gap-20 z-30">
          <div data-aos="fade-up">
            <h6 className={CONST.H6_CLASS}>システム開発支援</h6>
            <p>大規模な基幹業務や業務システムの開発を得意とします。チーム体制でご提案が可能です。</p>
            <p>&lt;得意な業務&gt;</p>
            <p>航空運輸、バンキング、証券、カード・与信、食品製造業、流通POS、など</p>
            <p>&lt;得意な技術&gt;</p>
            <p>Java、.NET、汎用機、など</p>
          </div>
          <div data-aos="fade-up" data-aos-delay="200">
            <h6 className={CONST.H6_CLASS}>受託システム開発</h6>
            <p>中・小規模なシステムをターゲットとしてオンプレミスからクラウドまでご対応が可能です。</p>
            <p>また、ユーザライクなＩ／Ｆ開発も得意としています。</p>
            <p>プライム案件を数多く手掛けており、これは当社の技術力とお客様からの信頼の高さの証です。</p>
            <p>&lt;得意な業務&gt;</p>
            <p>メーカー向け、コンシューマー向け、など</p>
            <p>&lt;得意な技術&gt;</p>
            <p>Java、PHP、VC++、C#、など</p>
          </div>
          <div data-aos="fade-up" data-aos-delay="300">
            <h6 className={CONST.H6_CLASS}>ソリューション提供</h6>
            <p>Webコンサルティング/CMS設計/構築/運用/保守/SEO分析・対策など、Webに関する全てをワンストップでサービス提供いたします。</p>
            <p>さらにスマートフォンも組み合わせて、独創的なUI開発にも対応可能です。</p>
            <p>&lt;得意な業務&gt;</p>
            <p>ECサイト構築とサービス提供、SNSサイト構築とサービス提供、など</p>
            <p>&lt;得意な技術&gt;</p>
            <p>オープンソースCMS(WordPress,EC-CUBE)、Android、iOS、Java、PHP、Objective-C、C#、Facebook/Twitter連携、など</p>
            {/* <MoreLink link_to="./solution/" add_class="w-8/12 md:w-10/12 xl:w-8/12 py-5 mt-12" text="VIEW ALL WORKS" /> */}
          </div>
        </div>
      </section>

      <section data-aos="fade-up" className={CONST.SECTION_AREA_CLASS}>
        <div className={CONST.CONTENTS_LEFT_IMG_AREA_CLASS}>
          <h2 className={CONST.H2_CLASS + CONST.PC_HIDDEN_CLASS}><span className="inline-block">環境（インフラ）</span><span className="inline-block">構築事業</span></h2>
          <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full -mb-10 xl:mb-0" src={img1} />
        </div>
        <div className={CONST.CONTENTS_RIGHT_MESSAGE_AREA_CLASS + " 3xl:-mt-20"}>
          <div>
            <h2 className={CONST.H2_CLASS + CONST.PC_SHOW_CLASS}>環境<span className="text-lg">（インフラ）</span>構築事業</h2>
            <h6 className={CONST.H6_CLASS}>ネットワークインフラ構築</h6>
            <p>サーバやネットワークの構築について、企画/設計/構築などのサービス提供だけでなく、PCキッティング等も行います。</p>
            <p>ＮＷ機器メーカー：Cisco、Juniper</p>
            <p>ＯＳ系：Linux、Windows、ActiveDirectory</p>
            <p>ミドルウェア：Apache、Tomcat、PostgreSQL、MySQL</p>
            <p className={CONST.LINE_SPACING_PB_CLASS}>その他：SDN(OpenFlow)、仮想化(VM)</p>
            <h6 className={CONST.H6_CLASS}>ＩＴシステム運用</h6>
            <p>WebサイトやITシステム保守、運用監視をサポート致します。</p>
            <p className={CONST.LINE_SPACING_PB_CLASS}>オンプレミスだけでなく、各種クラウド環境にも対応できます。</p>
            <h6 className={CONST.H6_CLASS}>サーバ構築業務</h6>
            <p>サーバやＰＣ端末等のハード機器、ＯＳ及びミドルウェア等の&quot;システム基盤の構築&quot;業務を行っています。</p>
            <p className={CONST.LINE_SPACING_PB_CLASS}>大企業向けのクラウドサービスを提供しているサーバ運用保守業務にも携わり、仮想化、ストレージ、セキュリティアプライアンス等、これからの最新技術にも身近に触れております。</p>
            <h6 className={CONST.H6_CLASS}>ネットワーク構築業務</h6>
            <p>ＩＴで欠かせない、拠点間や機器間を繋ぐ&quot;通信ネットワークの構築&quot;業務を行っています。</p>
            <p>ネットワーク機器のシェア№１である、CISCOの製品を多数扱う案件に携わり、最新機種や大小各規模向けのたくさんの製品の使用経験を持っております。</p>
          </div>
        </div>
      </section>

      <section data-aos="fade-up" className={CONST.SECTION_AREA_REVERSE_CLASS}>
        <div className={CONST.CONTENTS_LEFT_MESSAGE_AREA_CLASS}>
          <div>
            <h2 className={CONST.H2_CLASS + CONST.PC_SHOW_CLASS}>情報セキュリティーサービス事業</h2>
            <h6 className={CONST.H6_CLASS}>セキュリティーソリューション</h6>
            <p className={CONST.LINE_SPACING_PB_CLASS}>長年のソフトウェア開発の現場で得た経験から、情報セキュリティの重要性と護る事の大変さを理解しており、ITシステムの提供者と利用者のそれぞれに「情報セキュリティの重要性」を伝えるために、取組んでおります。</p>
            <h6 className={CONST.H6_CLASS}>コンサルティング</h6>
            <p className={CONST.LINE_SPACING_PB_CLASS}>IT環境のリスクの[管理]／脅威への[対策]／攻撃の[検知]／システムの[運用]等、情報セキュリティの様々な悩み事や不安の解消へ、親切丁寧に対応致します。</p>
            <h6 className={CONST.H6_CLASS}>ISMS認証取得/CSIRT構築支援</h6>
            <p>社内で培った取組みのノウハウを活用して、ISMSの認証取得やCSIRTの構築を支援致します。</p>
            <div data-aos="fade-right"><MoreLink link_to="./security_service/" add_class="" text="ACHIEVEMENT" /></div>
          </div>
        </div>
        <div className={CONST.CONTENTS_RIGHT_IMG_AREA_CLASS}>
          <h2 className={CONST.H2_CLASS + CONST.PC_HIDDEN_CLASS}><span className="inline-block">情報セキュリティー</span><span className="inline-block">サービス事業</span></h2>
          <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full -mb-10 xl:mb-0" src={img2} />
        </div>
      </section>

      <section data-aos="fade-up" className={CONST.SECTION_PB_AREA_CLASS}>
        <div className={CONST.CONTENTS_LEFT_IMG_AREA_CLASS}>
          <h2 className={CONST.H2_CLASS + CONST.PC_HIDDEN_CLASS}>教育事業</h2>
          <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full -mb-10 xl:mb-0" src={img3} />
        </div>
        <div className={CONST.CONTENTS_RIGHT_MESSAGE_AREA_CLASS}>
          <div>
            <h2 className={CONST.H2_CLASS + CONST.PC_SHOW_CLASS}>教育事業</h2>
            <h6 className={CONST.H6_CLASS}>ITエンジニア教育</h6>
            <p>長年に渡って実施してきた「充実した社内研修」を社外にも公開しています。実務経験豊富なＳＥと若いエネルギッシュなＳＥによる「実践的なプログラミング・スクール」です。未経験者向けの新人教育から、実務経験者向けのスキルアップ教育まで、幅広く対応します。</p>
            <div data-aos="fade-left"><MoreLink link_to="./education/curriculum/" add_class="" text="CURRICULUM DETAILS" /></div>
          </div>
        </div>
      </section>
    </Layout >
  );
}

export default IndexPage;
